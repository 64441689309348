@charset "UTF-8";

@font-face {
  font-family: "hamropalika";
  src:url("fonts/hamropalika.eot");
  src:url("fonts/hamropalika.eot?#iefix") format("embedded-opentype"),
    url("fonts/hamropalika.woff") format("woff"),
    url("fonts/hamropalika.ttf") format("truetype"),
    url("fonts/hamropalika.svg#hamropalika") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "hamropalika" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "hamropalika" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bank-1:before {
  content: "\63";
}
.icon-banking:before {
  content: "\64";
}
.icon-building-1:before {
  content: "\65";
}
.icon-city-hall-building-architecture-1:before {
  content: "\66";
}
.icon-natural-resources-1:before {
  content: "\67";
}
.icon-economy:before {
  content: "\68";
}
.icon-emergency:before {
  content: "\69";
}
.icon-employee:before {
  content: "\6a";
}
.icon-factory-1:before {
  content: "\6b";
}
.icon-government-1:before {
  content: "\6c";
}
.icon-park-1:before {
  content: "\6d";
}
.icon-police-station-1:before {
  content: "\6e";
}
.icon-population:before {
  content: "\6f";
}
.icon-safety:before {
  content: "\70";
}
.icon-temple-1:before {
  content: "\71";
}
.icon-leadership:before {
  content: "\72";
}
.icon-infrastructure:before {
  content: "\73";
}
.icon-hospital:before {
  content: "\74";
}
.icon-home:before {
  content: "\75";
}
.icon-ic-borrowed-books:before {
  content: "\76";
}
.icon-area-with-pins:before {
  content: "\61";
}
.icon-map-marker:before {
  content: "\62";
}
.icon-farm:before {
  content: "\77";
}
