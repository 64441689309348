// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
@import "./assets/css/custom_vars.scss";
// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
	@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
	@import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
@font-face {
	font-family: "Kalimati-Regular";
	src: local("Kalimati-Regular"),
		url(./assets/css/fonts/Kalimati-Regular.otf) format("opentype");
	/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
// $font-family-base: "Kalimati-Regular", "sans-seriff";
.example-highlight > pre {
	background: none transparent !important;
	margin: 0 !important;
	padding-top: 0.5rem;
	code {
		overflow-y: auto;
		display: block;

		span {
			background: none transparent !important;
		}
	}
}

.json > pre {
	background: none #fff !important;
}

.example-code > .json {
	background: none #fff !important;
}

.symbol.symbol-45 > svg {
	width: 100%;
	max-width: 45px;
	height: 45px;
}

.react-bootstrap-table {
	overflow-x: auto;
}

.react-bootstrap-table {
	th {
		outline: none;

		&.sortable {
			.svg-icon-sort {
				opacity: 0;
			}

			&:hover {
				cursor: pointer;

				.svg-icon-sort {
					opacity: 1;
				}
			}
		}
	}

	.table.table-head-custom thead tr {
		.sortable-active {
			color: $primary !important;
		}
	}
}

.cursor-default {
	cursor: default !important;
}
img.hamropalika-logo {
	height: 90px;
	// margin-top: 12px;
}
.font-weight-black {
	font-weight: 900;
}
.w-49 {
	width: 49%;
}
.mun-id-desc {
	color: #5c5c5c;
	font-size: 17px;
}
.sticky-side-menu {
	width: 50px;
	position: fixed;
	top: 20%;
	left: 1rem;
	list-style: none;
	margin: 0;
	z-index: 50;
	background: #ffffff;
	box-shadow: $dropdown-box-shadow;
	// display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: none;

	@include border-top-left-radius($border-radius);
	@include border-bottom-left-radius($border-radius);
	@include border-top-right-radius($border-radius);
	@include border-bottom-right-radius($border-radius);
	&.active {
		display: flex;
	}
	li {
		// &:hover a {
		//   * {
		//     fill: #fff;
		//   }
		// }
		&.active {
			a {
				* {
					fill: #007eff;
					color: #007eff;
				}
			}
			// &:hover a {
			//   * {
			//     fill: #fff;
			//   }
			// }
		}
	}
}
.pie-chart-container {
	.apexcharts-legend {
		justify-content: space-between !important;
	}
}
.numeric-icons {
	width: 43px;
	height: 43px;
}
.primary-icon {
	* {
		fill: #007eff;
		// fill: #56caa2;
	}
	&.filter-icon {
		width: 45px;
	}
}
// .sticky-nav-icon {
//   * {
//     fill: #3699ff;
//   }
//   &:hover {
//     * {
//       fill: #ebebeb;
//     }
//   }
// }
.inactive-icon:hover {
	* {
		fill: #ebebeb;
	}
}
.icon-container {
	border-radius: 50%;
	height: 50px;
	width: 50px;
	display: flex;
	margin-right: 10px;
	// position: relative;
	// align-items: center;
	justify-content: center;
	i {
		font-size: 30px;
		position: relative;
		top: 5px;
	}
}
.institution-item {
	margin-right: 1rem;
}
.header-logo {
	img {
		height: 100px;
	}
}
img.representative {
	height: 100px;
	width: 104px;
	object-fit: cover;
}
.text-copyright {
	color: #3b3b3b;
}
.gender {
	h6 {
		font-size: 16px;
		font-weight: 700;
	}
	p {
		font-size: 17px;
		font-weight: 600;
		margin-bottom: 0.5rem;
	}
	.gender-item {
		flex: 40%;
	}
}
.numeric-stat {
	h3 {
		font-size: 26px;
	}
}
.family {
	p {
		font-weight: 600;
	}
	h6 {
		font-weight: 700;
	}
}
.card {
	border-radius: 12px;
	width: 100%;
}
// .emergency-button{
//   i{
//     position: relative;
//     top: -4px;
//   }
// }
.person-details {
	h6 {
		font-size: 12px;
	}
}
.modal-content .fa-times {
	cursor: pointer;
}
.y-axis-desc {
	transform: rotate(-90deg);

	/* Legacy vendor prefixes that you probably don't need... */

	/* Safari */
	-webkit-transform: rotate(-90deg);

	/* Firefox */
	-moz-transform: rotate(-90deg);

	/* IE */
	-ms-transform: rotate(-90deg);

	/* Opera */
	-o-transform: rotate(-90deg);

	/* Internet Explorer */
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	position: absolute;
	top: 30%;
	left: 2rem;
}
.back-icon {
	position: relative;
	top: 4px;
	&:hover {
		color: rgba(#439c7dfa, 0.4);
		// color: rgba(#56caa2, 0.4);
	}
	cursor: pointer;
}
.pie-chart-container {
	.apexcharts-legend {
		display: flex;
		flex-wrap: unset !important;
		.apexcharts-legend-series {
			min-width: 120px;
		}
	}
}
.dropdown-menu {
	overflow-x: auto;
	max-height: 80vh;
}
.side-menu-item.icon {
	font-size: 38px !important;
	color: #3b3b3b;
}
.section-icon {
	color: #439c7dfa;
	// color: #56caa2;
	font-size: 4.25em !important;
}
.language-selector {
	.dropdown-menu {
		min-width: 5.5rem;
	}
}
.modal {
	z-index: 9999;
}
.stop-scrolling {
	height: 100%;
	overflow: hidden;
}
.apexcharts-canvas ::-webkit-scrollbar-track {
	background: #dedede;
}
.nepali-lang-select {
	line-height: 1.6;
}
.non-selected-lang-color {
	color: black !important;
}
.MuiTypography-body1 {
	font-family: inherit !important;
}
@media screen and (min-width: 1400px) and (max-width: 1470px) {
	.custom-container.sticky-padding {
		padding-left: 2.5rem;
	}
}
@media screen and (min-width: 1200px) and (max-width: 1270px) {
	.custom-container.sticky-padding {
		padding-left: 3rem;
	}
}
@media screen and (max-width: 1175px) {
	.custom-container.sticky-padding {
		padding-left: 5.25rem;
	}
}
@media screen and (min-width: 992px) {
	.topbar {
		height: 100%;
	}
}

.numeric-title {
	font-size: 1.73rem;
}
